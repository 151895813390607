:root {
    --main-radius: 5px;
    --main-padding: 5px;
}
.projects-page {
    padding-top: 90px;
    width: 100%;
    display: grid;
    height: 100vh;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    grid-template-rows: 1.5fr 1.5fr 1.5fr 0.8fr;
    grid-template-areas: "robotics content1 content1 content1" "robotics content2 content2 content2" "robotics content3 content3 content3" "robotics footer footer footer";
    grid-gap: 0.2rem;
    font-weight: 800;
    text-transform: uppercase;
    font-size: 12px;
    color: #004d40;
    text-align: center;
}

.box-highlight-text {
    width: 100%;
    color: #004d40;
    font-size: 30px;
    display: flex; /* establish flex container */
    justify-content: center; /* center flex items horizontally, in this case */
    align-items: center; /* center flex items vertically, in this case */
    text-align: center;
}

#main {
    background: #84ffff;
    grid-area: main;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
}

#robotics {
    background: #004d40;
    grid-area: robotics;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    background-image: url("robotics.gif");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    min-height: 200px;
}

#content1 {
    display: flex;
    background: #28625C;
    grid-area: content1;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    background-image: url("code.gif");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

.contentgeneral {
    display: flex;
    background: #28625C;

}

#content2 {
    display: flex;
    background: #4F838B;
    background-image: url("city.jpeg");
    grid-area: content2;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    background-size: cover; /* <------ */
    background-repeat: no-repeat;
    background-position: center center;
}
#content2:before {
    position: absolute;
    background: #fff;
    animation: lighting .3s linear infinite;
    opacity: 0.5;
}
@keyframes rain {
    0% {
        background-position: 0% 0%;
    }
    100% {
        background-position: 100% -50%;
    }
}
@keyframes lighting {
    0% {
        opacity: 0;
    }
    10% {
        opacity: 0;
    }
    11% {
        opacity: 1;
    }
    14% {
        opacity: 0;
    }
    20% {
        opacity: 0;
    }
    24% {
        opacity: 1;
    }
}

#content3 {
    display: flex;
    background: #28625C;
    grid-area: content3;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
    background-image: url("discord.png");
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
}

footer {
    background: #1de9b6;
    grid-area: footer;
    border-radius: var(--main-radius);
    padding-top: var(--main-padding);
}

a {
    text-align: center;
    display: block;
    font-family: inherit;
    text-decoration: none;
    font-weight: bold;
    margin: 1rem;
}

@media only screen and (max-width: 550px) {
    .projects-page {
    grid-template-columns: 1fr;
    grid-template-rows: 0.4fr 0.4fr 2.2fr 1.2fr 1.2fr 1.2fr 1fr;
    grid-template-areas: "robotics" "content1" "content2" "content3" "footer";
}
}




/* ------------------------Document div----------------------*/

.ProjectDocument {
    padding: 10px;
    font-family: Roboto, Arial, sans-serif;
    color: #F5F5F5; /* Light text color for dark theme */
    position: relative;
}

    /* Style for Tables */
    .ProjectDocument table {
        width: 100%;
        border-collapse: collapse;
        margin-bottom: 20px;
        table-layout: fixed; /* this helps with responsiveness */
    }

    .ProjectDocument th,
    .ProjectDocument td {
        border: 1px solid #398b82;
        padding: 10px;
        word-wrap: break-word; /* this helps with responsiveness */
    }

    .ProjectDocument th {
        background-color: #398b82;
        color: white;
        text-align: left;
    }

    /* Style for Headers */
    .ProjectDocument h1,
    .ProjectDocument h2,
    .ProjectDocument h3,
    .ProjectDocument h4,
    .ProjectDocument h5,
    .ProjectDocument h6 {
        color: #F5F5F5;
        margin-top: 20px;
        margin-bottom: 10px;
    }

    .ProjectDocument h1, h2 {
        border-left: 5px solid #398b82; /* Adding a left border instead of underline */
        padding-left: 10px;
    }

    /* Style for Paragraphs */
    .ProjectDocument p {
        line-height: 1.5;
        margin-bottom: 20px;
    }

    /* Style for Images - if any */
    .ProjectDocument img {
        max-width: 100%;
        height: auto;
    }

    /* Style for links */
    .ProjectDocument a {
        color: #398b82;
    }

/* Responsive design */
@media (max-width: 600px) {
    .ProjectDocument table,
    .ProjectDocument th,
    .ProjectDocument td {
        font-size: 14px;
    }
}


