.chat-page {
    display: flex;
    height: 100vh;
}

.chats-list {
    width: 30%;
    overflow-y: auto;
    border-right: 1px solid #ccc;
}

.chat-item {
    padding: 10px;
    cursor: pointer;
}

    .chat-item:hover {
        background-color: #eee;
    }

.messages-container {
    width: 70%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.message {
    padding: 10px;
    margin: 5px;
    border-radius: 5px;
}

.user-message {
    background-color: #f0f0f0;
    align-self: flex-end;
    margin-right: 10px;
}

.assistant-message {
    background-color: #e0e0e0;
    align-self: flex-start;
    margin-left: 10px;
}

.message-input-container {
    padding: 10px;
    display: flex;
    border-top: 1px solid #ccc;
}

.message-input {
    flex: 1;
    padding: 5px;
    border: none;
    border-radius: 5px;
    outline: none;
}

.send-message-button {
    margin-left: 10px;
    padding: 5px 10px;
    background-color: #007bff;
    color: white;
    border: none;
    border-radius: 5px;
    cursor: pointer;
}

    .send-message-button:hover {
        background-color: #0056b3;
    }
