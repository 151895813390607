
.paraContainer {
    top: 0px;
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 620px;
}
.parallax-container {
    position: relative;
    overflow: hidden;
    width: 100%;
    height: 700px;
    margin-bottom: 75px;
}
  .parallax-layer {
    width: 100%;
    height: 600px;
    position: absolute;
    left: 0;
    top: 0;
    background-position: bottom center;
    background-repeat: repeat-x;
    background-size: 960px 253px;
  }
.layer-0 {
    top: 0;
    z-index: 5;
    background: #dbf0ff url('workshop.png') no-repeat top 110px center;
    background-size: 70%;
}
.layer-1 {
    top: -70px;
    z-index: 5;
    background-image: url('004.png');
}

.layer-2 {
    top: 40px;
    z-index: 10;
    background-image: url('003.png');
    background-size: 100% auto;
}

.layer-3 {
    top: 50px;
    z-index: 15;
    background-image: url('002.png');
    background-size: 100% auto;
}

.layer-4 {
    top: 90px;
    z-index: 50;
    background-image: url('001.png');
    background-size: 100% auto;
}
.layer-5 {
    top: 170px;
    z-index: 25;
    background-image: url('000.png');
    background-repeat: no-repeat;
    width: 100%;
    background-position: left;
    background-size: contain;
}

.layer-6 {
    top: 670px;
    z-index: 30;
    background: #345c5c;
    height: 500px;
}
@media screen and (max-width: 992px) {
    .layer-5 {
        top: 220px;
        
    }
    .layer-4 {
        top: 4px;
    }
    .layer-3 {
        top: -10px;
    }
    .layer-2 {
        top: -20px;
    }
    .layer-1 {
        top: -20px;
    }

    .layer-6 {
        top: 600px;
    }

    .parallax-container {
        width: 101%;
    }
}



.content {
    position: absolute;
    z-index: 35;
    top: 600px;
    background: #345c5c;
    width: 100%;
    height: 400px;
}

@media (max-width: 768px) {
    .content {
        height: 400px; /* Adjust the height as desired for smaller screens */
    }
}