.ListItemBox {
    color: black;
    display: flex;
    width: 90%; /* 90% width */
    margin: auto; /* centered */
    background-color: #f5f5dc; /* creme background */
    border-radius: 30px; /* rounded corners */
    overflow: hidden; /* ensures child elements don't spill over rounded corners */
    transition: transform 0.3s ease-in-out; /* for hover effect */
}

.project-image {
    flex: 0.25; /* takes up 25% of the container */
    object-fit: cover; /* to make sure the image covers the space */
    width: 100%; /* ensures the image takes up the full width of its container */
    height: auto; /* ensures the image height adjusts based on its width */
    max-width: 25%; /* ensures that the width does not exceed 25% */
}

.project-text {
    flex: 0.75; /* takes up 75% of the container */
    padding: 20px; /* some padding to make it look nice */
    background-color: #f5f5dc; /* creme background */
}

    .project-text h1 {
        margin: 0;
        margin-bottom: 10px; /* space between title and description */
    }

.ListItemBox:hover {
    transform: translateY(-10px); /* moves up */
    box-shadow: 0px 10px 20px rgba(0, 0, 0, 0.2); /* adds a glow */
}

@media (max-width: 600px) {
    .project-image {
        flex: 1;
    }

    .project-text {
        flex: 1;
        padding: 10px; /* decrease the padding on smaller screens */
    }

        .project-text h1 {
            font-size: 1.2em; /* decrease the font size on smaller screens */
        }

        .project-text p {
            font-size: 0.8em; /* decrease the font size on smaller screens */
        }
}
