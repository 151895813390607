.Postscontainer {
    text-align: center;
    width: 100%;
    overflow: hidden;
    margin: auto;
}

.Postschild {
    overflow: hidden;
    display: block;
    border-style: none;
    width: 105%;
    height: 900px;
}

.Posts {
    margin-top: 60px;
    text-align: center;
    overflow: hidden;
    display: block;
}

.mainheader {
    color: #398b82;
    font-size: 40px;
}

.PostsInfoContainer {
    max-width: 1360px;
    margin: 0 auto; /* This centers the container horizontally */
}

@media only screen and (max-width: 730px) {
    .PostsInfocontainer {
        width: 330px;
        margin: 0 auto;
    }
}

@media only screen and (min-width: 1300px) {
    .Postscontainer {
        width: 800px;
    }
}