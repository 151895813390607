
.coolcontainer {
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
    flex-wrap: wrap; /* this allows the items to wrap to a new line if necessary */
}

.card {
    width: 300px;
    height: 500px;
    position: relative;
    transform-style: preserve-3d;
    transform: translateZ(-140px);
    transition: transform 350ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
    cursor: pointer;
}

    .card > div {
        position: absolute;
        width: 320px;
        height: 450px;
        padding: 34px 21px;
        transition: all 350ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
    }

.front {
    background-image: linear-gradient(180deg, rgba(60, 147, 155, 1) 0%, rgba(127, 226, 196, 0.1) 100%);
    transform: rotateY(0deg) translateZ(160px);
    border-radius: 34px 3px 0 0;
    text-align: left;
}

.right {
    background-image: linear-gradient(0deg, rgba(60, 147, 155, 1) 0%, rgba(127, 226, 196, 0.1) 100%);
    opacity: 0.08;
    transform: rotateY(90deg) translateZ(160px);
    border-radius: 0 0 3px 34px;
}

.card:hover {
    transform: translateZ(-160px) translateX( -10px) rotateY( -90deg);
}

    .card:hover .front {
        opacity: 0;
    }

    .card:hover .right {
        opacity: 1;
    }

span {
    margin-left: 13px;
    opacity: .55;
}

.moveimg {
    transform-origin: top right;
    transition: transform 300ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
    transition-delay: 100ms;
    transform: translateX(21%) rotateZ(13deg) skewX(3deg);
    pointer-events: none;
}

.img-wrapper {
    animation: float 4s cubic-bezier(0.390, 0.575, 0.565, 1.000) infinite alternate;
    position: absolute;
    top: 0;
    right: 0;
    pointer-events: none;
    backface-visibility: hidden;
}

@keyframes float {
    0% {
        transform: translateZ(20px);
    }

    100% {
        transform: translateY(-21px) translateX(-13px) translateZ(30px);
    }
}

.card:hover ~ .img-wrapper img {
    transform: scale(0.9) translateX(77%) translateY(90%) rotateZ(80deg);
}

.itemul {
    margin-left: 21px;
    padding: 0;
    font-size: 16px;
    font-weight: 300;
    list-style: none;
}

.itemli {
    padding-bottom: 8px;
    position: relative;
}

    .itemli:before {
        content: 'x';
        position: absolute;
        left: -21px;
        opacity: .55;
    }

.itembutton {
    position: absolute;
    right: 21px;
    bottom: 34px;
    border: none;
    box-shadow: none;
    background: none;
    color: inherit;
    font-family: 'Exo 2';
    font-weight: 300;
    font-size: 15px;
    letter-spacing: -.25px;
    font-weight: 700;
    padding: 13px 34px;
    border-radius: 55px 55px 21px 55px;
    background-image: linear-gradient(130deg, rgba(161, 123, 130, 1) 50%, rgba(161, 123, 130, 0.89) 100%);
    background-size: 125% 100%;
    background-position: right;
    cursor: pointer;
    box-shadow: 8px 5px 13px rgba(34,34,34,.08);
    transform: scale(0) skewY(13deg);
    transition: all 150ms cubic-bezier(0.390, 0.575, 0.565, 1.000);
    transform-origin: right bottom;
}

.card:hover button {
    transform: scale(1) skewY(0);
}

.card:not(:hover) button {
    opacity: 0;
}


.price {
    position: absolute;
    bottom: 34px;
    left: 21px;
    font-size: 34px;
    opacity: .34;
}

@keyframes fadeIn {
    0% {
        opacity: 0.33;
        transform: scale(.89);
    }
}

@media only screen and (max-width: 600px) {

    .moveimg {
        transform: scale(.80);
    }



    .grid-container-split {
        display: grid;
        grid-template-columns: auto;
        padding: 10px;
    }
}
