.sidebar {
    position: fixed;
    right: 0;
    top: 0;
    width: 40px; /* Adjust as needed */
    height: 110vh;
    background-color: transparent;
    color: white;
    overflow: auto;
    transition: width 0.3s ease, background-color 0.3s ease;
}

    .sidebar::before {
        content: "⇔"; /* This is a Unicode character for a double arrow */
        position: absolute;
        bottom: 100px; /* Adjust as needed */
        right: 0px; /* Adjust as needed */
        transform: none;
        color: white;
        padding: 10px; /* Adjust as needed */
        background-color: #398b82;
        border: 2px solid black; /* Adjust as needed */
        border-radius: 10%; /* This makes the box circular. Adjust or remove as needed. */
        transition: opacity 0.3s ease;
    }
    @media (min-width: 769px) {
        .sidebar::before {
            /* Reset to original values for larger screens */
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
            bottom: initial;
            right: initial;
        }
    }

    .sidebar:hover::before {
        opacity: 0;
    }

    .sidebar:not(:hover)::before {
        opacity: 1;
    }


    .sidebar:hover {
        width: 200px; /* Adjust as needed */
        background-color: #398b82;
    }

    .sidebar a {
        display: block;
        text-decoration: none;
        color: white;
        padding: 10px;
        transition: padding-left 0.3s ease;
        opacity: 0;
        transition: opacity 0.3s ease;
    }

    .sidebar:hover a {
        opacity: 1;
    }

    .sidebar a[href^="#heading-1"] {
        padding-left: 10px;
    }

    .sidebar a[href^="#heading-2"] {
        padding-left: 20px;
    }

    .sidebar a[href^="#heading-3"] {
        padding-left: 30px;
    }

.Sidebar-h1 {
    font-size: 1.5em;
}

.Sidebar-h2 {
    font-size: 1.2em;
}

.Sidebar-h3 {
    font-size: 1em;
}

.Sidebar-h4 {
    font-size: 0.9em;
}

.Sidebar-h5 {
    font-size: 0.8em;
}

.Sidebar-h6 {
    font-size: 0.7em;
}

